import React from 'react';
import { Helmet } from 'react-helmet';
import favicon from '../assets/favicon.ico';
import LogoIcon from '../svg/LogoIcon';

export default () => (
  <section className="p-20">
    <Helmet>
      <title>VIACCE | Business & Technology</title>
      <link rel="icon" href={favicon} />
      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      <meta
        content="Empresa de negocios y tecnología | Outsourcing. Somos una empresa que busca transformar la forma habitual de desarrollar negocios, generando valor a tu empresa, trascendiendo la tecnología que implementas y creando la mejor experiencia para tus clientes."
        name="description"
      />
      <meta
        content="Software, Business, Development, Processes, Strategies, Customer Experience, Mystery shopper, Services, Outsourcing, IT, Consulting"
        name="keywords"
      />
      <meta content="#001847" name="theme-color" />
      <meta content="#001847" name="msapplication-navbutton-color" />
      <meta content="#001847" name="apple-mobile-web-app-status-bar-style" />
    </Helmet>
    <h1 className="text-center text-2xl font-bold">Política de privacidad</h1>
    <p className="pt-10 text-justify">
      El presente Política de Privacidad establece los términos en que VIACCE | Business &
      Technology usa y protege la información que es proporcionada por sus usuarios al momento de
      utilizar su sitio web. Esta compañía está comprometida con la seguridad de los datos de sus
      usuarios. Cuando le pedimos llenar los campos de información personal con la cual usted pueda
      ser identificado, lo hacemos asegurando que sólo se empleará de acuerdo con los términos de
      este documento. Sin embargo esta Política de Privacidad puede cambiar con el tiempo o ser
      actualizada por lo que le recomendamos y enfatizamos revisar continuamente esta página para
      asegurarse que está de acuerdo con dichos cambios.
    </p>

    <h2 className="mt-10 font-bold">Información recopilada</h2>
    <p className="mt-2">
      Nuestro sitio web podrá recoger información personal por ejemplo: nombre, correo electrónico y
      número de teléfono. Así mismo cuando sea necesario podrá ser requerida información específica
      para procesar alguna solicitud de servicio, contacto o cotización.
    </p>

    <h2 className="mt-10 font-bold">Uso de la información recopilada</h2>
    <p className="mt-2">
      Nuestro sitio web emplea la información con el fin de proporcionar el mejor servicio posible,
      particularmente para mantener un registro de clientes, de solicitudes de servicio en caso que
      aplique, y mejorar nuestros productos y servicios. El objetivo es ofrecer la mejor experiencia
      al momento de contactar al cliente.
    </p>
    <p className="mt-2">
      VIACCE | Business & Technology está altamente comprometido para cumplir con el compromiso de
      mantener su información segura. Su información personal queda completamente resguardada y no
      es compartida con ninguna entidad bajo ningún término.
    </p>

    <h2 className="mt-10 font-bold">Uso de nuestro chatbot Zyrus</h2>
    <p className="mt-2">
      VIACCE | Business & Technology se compromete a proteger la información personal recopilada a
      través de conversaciones efectuadas mediante nuestro chatbot Zyrus. Cualquier información no
      relevante o de caracter no personal, no es almacenada por nosotros y queda completamente
      descartada y eliminada una vez ha concluído la conversación con nuestro chatbot.
    </p>

    <h2 className="mt-10 font-bold">Control de su información personal</h2>
    <p className="mt-2">
      En cualquier momento usted puede restrigir el uso de su información personal compartida con
      VIACCE | Business & Technology, en caso de ya no querer continuar con el proceso de cotización
      o negociación, a no ser que exista un contrato de proyecto firmado en donde se especifique lo
      contrario bajo razones particulares.
    </p>
    <p className="mt-2">
      Esta compañía no venderá, cederá ni distribuirá la información personal que es recopilada sin
      su consentimiento, salvo que sea requerido por un juez con una orden judicial.
    </p>

    <p className="mt-10">
      VIACCE | Business & Technology se reserva el derecho de cambiar los términos de la presente
      Política de Privacidad en cualquier momento.
    </p>

    <div className="flex justify-center mt-20">
      <LogoIcon />
    </div>
    <h2 className="mt-2 text-center font-bold text-primary">Desarrollamos el presente y programamos el mañana</h2>
  </section>
);
